import __NS_DYNAMIC_REACT_CONTENT___0 from "./index.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { CurrentYear } from 'apps/acp/packages/date';
export const CopyrightStatement = ({
  statement
}) => {
  return createElement(Content, {
    hash: "4ba5c1173379a31a4734cbec28d55386",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(CurrentYear, null, children), () => statement || createElement(Fragment, null, "Netspend Corporation. All rights reserved worldwide. Netspend is the federally registered U.S. service mark of Netspend Corporation. All other trademarks and service marks belong to their owners.")],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
};